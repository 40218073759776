import { Link } from "react-router-dom";
import mainLogo from "../../assets/img/logo.png";
import GeneralInfo from "../GeneralInfo";

const OffsetMenu = ({ offset, handleOffset }) => {
  return (
    <>
      <div className={offset ? "offset-menu" : "offset-menu show"}>
        <span onClick={handleOffset} id="offset-menu-close-btn">
          <i className="fal fa-plus"></i>
        </span>
        <div className="offset-menu-wrapper text-white">
          <div className="offset-menu-header">
            <div className="offset-menu-logo">
              <Link to="/home">
                <img src={mainLogo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="offset-menu-section">
            <h3>About Us</h3>
            <p>
              Our specializations and expertise in the Fire Fighting Systems,
              HVAC, Plumbing & Drainage and Environmental Engineering fields
              enables us in clearly understanding customer requirements,
              designing the perfect system, identifying the best & cost
              efficient products.
            </p>
            <Link to="/contact" className="theme-btn  mt-30">
              Consultation
            </Link>
          </div>
          <div className="offset-menu-section">
            <h3>Contact Info</h3>
            <ul>
              <li>
                <span>
                  <i className="fal fa-map"></i>
                </span>
                Fire DOC Trading & Contracting L.L.C
              </li>
              <li>
                <span>
                  <i className="fal fa-phone"></i>
                </span>
                {GeneralInfo.companyLan}
              </li>
              <li>
                <span>
                  <i className="fal fa-mobile"></i>
                </span>
                {GeneralInfo.companyPh}
              </li>
              <li>
                <span>
                  <i className="fal fa-envelope"></i>
                </span>
                {GeneralInfo.companyEmail}
              </li>
              <li>
                <span>
                  <i className="fal fa-globe"></i>
                </span>
                {GeneralInfo.companyWebsite}
              </li>
            </ul>
          </div>
          <div className="offset-menu-footer">
            <div className="offset-menu-social-icon">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="#">
                <i className="fab fa-dribbble"></i>
              </a>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffsetMenu;
